import styles from './contacts.module.scss';
import { Container } from '@/components/ui/Grid';
import MotionHeader from '@/components/ui/MotionHeader';
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

const variants = {
  initial: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 1.3,
      delay: 1,
    },
  },
  hidden: { opacity: 0 },
};

const Contacts = ({ data = { offices: [] } }) => {
  const { t } = useTranslation(['common', 'contacts']);
  const [activeOfficeId, setActiveOfficeId] = useState(
    data.activeOfficeSlug
      ? data.offices.find(({ slug }) => slug === data.activeOfficeSlug).id
      : data.offices[0].id
  );
  const [schemesListOpened, setSchemesListOpened] = useState(false);

  useEffect(() => {
    const listener = event => {
      if (
        !event.target.classList.contains(`.${styles.schemesListWrapper}`) &&
        !event.target.closest(`.${styles.schemesListWrapper}`)
      ) {
        setSchemesListOpened(false);
      }
    };
    if (schemesListOpened) {
      document.addEventListener('click', listener);
    } else {
      document.removeEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [setSchemesListOpened, schemesListOpened]);

  const activeOffice = useMemo(() => {
    return data.offices.find(({ id }) => id === activeOfficeId);
  }, [activeOfficeId]);
  const schemesLinks = useMemo(() => {
    return activeOffice?.schemes
      .map(({ title, scheme }) => ({ title, scheme }))
      .filter(({ scheme }) => !!scheme);
  }, [activeOffice]);
  const ymaps = useRef(null);
  const map = useRef(null);

  const bounds = useMemo(() => {
    const utils = ymaps.current?.util?.bounds;
    const points = activeOffice.points
      .map(({ coordinate }) => coordinate)
      .filter(coordinate => !!coordinate?.length);
    if (utils && activeOffice) {
      const result = utils.fromPoints(points);
      ymaps.current?.util
        ?.requireCenterAndZoom(map.current.getType(), result, map.current.container.getSize())
        .then(res => {
          map.current.setCenter(res.center, points.length > 1 ? res.zoom - 1 : 13);
        });
    }
  }, [ymaps.current, activeOffice, map.current]);

  return (
    <Container>
      {data.title && <MotionHeader className={styles.header}>{data.title}</MotionHeader>}
      <div className={styles.officesTabs}>
        {data.offices.map(office => (
          <div
            onClick={() => setActiveOfficeId(office.id)}
            key={office.id}
            className={`pointer ${styles.officeTabTitle} ${
              office.id === activeOfficeId && styles.activeTab
            }`}
          >
            {office.city}
          </div>
        ))}
      </div>
      <div className={styles.wrapper}>
        {activeOffice.points.length && activeOffice.points.length > 0 && (
          <motion.div
            className={styles.map}
            initial="initial"
            animate="visible"
            exit="hidden"
            variants={variants}
          >
            <YMaps
              query={{
                ns: 'use-load-option',
                load: 'Map,Placemark,util.bounds,util.requireCenterAndZoom,geoObject.addon.balloon',
              }}
            >
              <Map
                instanceRef={map}
                state={{
                  bounds,
                  center: activeOffice.points[0].coordinate,
                  zoom: 13,
                }}
                style={{ width: '100%', height: '500px' }}
                onLoad={ymapsInstance => {
                  ymaps.current = ymapsInstance;
                }}
              >
                <ZoomControl options={{ float: 'right' }} />
                {data.offices.map(({ points }) =>
                  points.map(point => {
                    return (
                      <Placemark
                        key={point.id}
                        defaultGeometry={point.coordinate}
                        properties={{
                          balloonContentBody: point.title || 'UNIFROST',
                        }}
                        options={{ iconColor: '#bca169', preset: 'islands#circleDotIcon' }}
                      />
                    );
                  })
                )}
              </Map>
            </YMaps>
          </motion.div>
        )}
      </div>
      <div className={styles.descWrapper}>
        <div className={styles.descColumn}>
          <div className={styles.descColumnTitle}>{t('Адрес')}</div>
          {activeOffice.addresses.map(address => (
            <div className={styles.address} key={address.id}>
              {address.address}
            </div>
          ))}
        </div>
        <div className={styles.descColumn}>
          <div className={styles.descColumnTitle}>{t('Почта')}</div>
          <div>{activeOffice?.email}</div>
        </div>
          <div className={styles.descColumn}>
            <div className={styles.descColumnTitle}>{t('Телефоны')}</div>
            <div>
              {activeOffice.phones.map(phone => (
                <div key={phone.id}>{phone.phone}</div>
              ))}
            </div>
          </div>
          <div className={styles.descColumn}>
            <div className={styles.descColumnTitle}>{t('Контакты для СМИ')}</div>
              <div className={styles.address}>press@unifrost.ru</div>
          </div>
          {!!schemesLinks.length && (
              <div className={clsx(styles.descColumn, styles.schemesColumn)}>
                <div className={styles.descColumnTitle}>{activeOffice?.text}</div>
                {schemesLinks.length > 1 ? (
                    <a
                        onClick={() => setSchemesListOpened(true)}
                        className={`rrs__button ${styles.downloadSchemeButton}`}
                    >
                      {t('Скачать схему проезда')}
                    </a>
                ) : (
                    <a
                        href={activeOffice.schemes[0].scheme}
                        target="_blank"
                        download
                        className={`rrs__button ${styles.downloadSchemeButton}`}
                    >
                      {t('Скачать схему проезда')}
                    </a>
                )}
                {schemesListOpened && (
                    <div className={styles.schemesListWrapper}>
                      <ul className={styles.schemesList}>
                        <li className={styles.schemesListCity}>{activeOffice.title}</li>
                        {schemesLinks.map(({title, scheme}) => (
                            <li key={scheme} className={styles.schemesListLink}>
                              <a
                                  onClick={() => setSchemesListOpened(false)}
                                  href={scheme}
                                  target="_blank"
                                  download
                              >
                                {title}
                              </a>
                            </li>
                        ))}
                      </ul>
                    </div>
                )}
              </div>
          )}
      </div>
    </Container>
  );
};

export default Contacts;
